.block.schedule-notice .schedule-dropdown {
    padding: 15px;
    margin-top: 0px;
}
.block.schedule-notice .schedule {
    display: grid;
    grid-template-columns: max-content auto;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    margin-bottom: 10px;
}
.block.schedule-notice .btn {
    min-height: 50px;
    min-width: 100px;
    padding: 4px 15px;
}
.block.schedule-notice .time {
    font-weight:bold;
    padding: 5px 15px 5px 5px;
}
.block.schedule-notice .program {
    padding: 5px 5px 5px 0;
}
.block.schedule-notice div.btn {
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.block.schedule-notice .description {
    font-weight: initial;
}
.block.schedule-notice .even {
    background-color: rgba(0,0,0,.08);
}